<template>
  <div class="foot-main">
    <van-field
      return-key-type="done"
      @enter="sendQuestion"
      v-model="msg"
      type="textarea"
      rows="1"
      autosize
      center
      clearable
      @click-left-icon="switchType"
    >
      <template #button>
        <van-button size="small" :disabled="lock" @click="sendQuestion" round type="info">发送</van-button>
      </template>
      <template #left-icon>
        <span class="left-icon iconfont icon-message" />
      </template>
    </van-field>
  </div>
</template>
<script>
export default {
  name: 'FootBar',
  components: {},
  props: {
    lock: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      msg: '',
    }
  },
  computed: {},
  watch: {},
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  methods: {
    sendQuestion() {
      if (this.msg != '') {
        this.$emit('question-msg', this.msg)
        this.msg = ''
      }
    },
    switchType() {
      console.log('switchType')
    },
  },
  beforeDestroy() {},
  destroyed() {},
}
</script>
<style scoped lang="less" type="text/less">
.foot-main {
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  bottom: 0px;
  left: 0;
  right: 0;
  // height: 50px;
  min-height: 50px;
  //   background-color: blue;
  .left-icon {
    font-size: 25px;
    margin-right: 5px;
  }
  /deep/ .van-field__control {
    border-radius: 25px;
    background-color: #f5f5f5;
    height: 30px;
    line-height: 30px;
    padding: 0 10px;
    font-size: 14px;
  }
}
</style>
