var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "foot-main" },
    [
      _c("van-field", {
        attrs: {
          "return-key-type": "done",
          type: "textarea",
          rows: "1",
          autosize: "",
          center: "",
          clearable: "",
        },
        on: { enter: _vm.sendQuestion, "click-left-icon": _vm.switchType },
        scopedSlots: _vm._u([
          {
            key: "button",
            fn: function () {
              return [
                _c(
                  "van-button",
                  {
                    attrs: {
                      size: "small",
                      disabled: _vm.lock,
                      round: "",
                      type: "info",
                    },
                    on: { click: _vm.sendQuestion },
                  },
                  [_vm._v("发送")]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "left-icon",
            fn: function () {
              return [
                _c("span", { staticClass: "left-icon iconfont icon-message" }),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.msg,
          callback: function ($$v) {
            _vm.msg = $$v
          },
          expression: "msg",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }